import React from 'react';
import CalendarComponent from '../components/calendar/calendar-component';

const Calendar = () => {
return (
	<div
	style={{
		display: 'flex',
		justifyContent: 'Right',
		alignItems: 'Right'
	}}>
	<CalendarComponent />
	</div>
);
};

export default Calendar;
