import React from 'react';
import '../../../App.css';

const churchGoogleMapLink = "https://www.google.com/maps/place/St+George+Orthodox+Church/@42.5487729,-83.1111279,15z/data=!4m14!1m7!3m6!1s0x8824c4e61dfb2d95:0x9d3ba28fccb1bbe1!2sSt+George+Orthodox+Church!8m2!3d42.5485042!4d-83.1036177!16s%2Fg%2F1tfvzs2f!3m5!1s0x8824c4e61dfb2d95:0x9d3ba28fccb1bbe1!8m2!3d42.5485042!4d-83.1036177!16s%2Fg%2F1tfvzs2f";

function handleToggle() {
	window.open(churchGoogleMapLink);
}

const ContactInfo = () => {
	return (
        <div className='contactMain'>
            <div className='contactDescription'>
                <p><b>Location:</b> 2160 E Maple Rd, Troy, MI 48083<br/>
                <b>Phone:</b> (248) 589-0480<br/>
                <b>Email Address:</b> office@stgeorgeoftroy.com</p>
            </div>
            <div className='contactMapDiv'>
                <button className='contactMap' style={{
                    backgroundImage: `url(${require("../../../assets/google-map.png")})`
                }} onClick={handleToggle} />
            </div>
        </div>
	);
};

export default ContactInfo;
