import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './components/navbar/navbar.js';
import './components/navbar/navbar.css';

import Footer from './components/footer/footer.js';
// import TermsOfUseBanner from './components/privacy-policy-banner/privacy-policy-banner.js';

import './App.css';

import Home from './pages/home.js';
import Events from './pages/events.js';
import Observers from './pages/observers.js';
import Calendar from './pages/calendar.js';
import Contact from './pages/contact.js';
// import PrivayPoliy from './pages/privacy-policy.js'
import EventDetail from './components/event/event-detail/event-detail.js'

const App = () => {
  return (
    <div className='app'>
    <Router>
    <div className='main'>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/observers' element={<Observers />} />
        <Route path='/calendar' element={<Calendar />} />
        <Route path='/events' element={<Events />} />
        <Route path='/events/id' element={<EventDetail />} />
        <Route path='/contact' element={<Contact />} />
        {/* <Route path='/privacy-policy' element={<PrivayPoliy />} /> */}
      </Routes>
      </div>
      <Footer />
      {/* <TermsOfUseBanner /> */}
    </Router>
    </div>
  );
}

export default App;
