import React from "react";
import { useLocation } from "react-router-dom";

function EventDetail() {
	const location = useLocation();
	const { state } = location;
    console.log(state);
    
    return(
        <div>
            <h1>This Page is Under Maintenance.</h1>
        </div>
    );
}

export default EventDetail;
