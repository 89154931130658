import React, { useState } from "react";
import './send-message.css';

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };

  return (
    <div className='sendMessage'>
        <h1 className='mainTitle'>Send A Message</h1>
        <form onSubmit={handleSubmit}>
        <div className="messageElem">
            <label htmlFor="name">Name: <br/></label>
            <input className="field" type="text" id="name" required />
        </div>
        <div className="messageElem">
            <label htmlFor="email">Email: <br/></label>
            <input className="field" type="email" id="email" required />
        </div>
        <div className="messageElem">
            <label htmlFor="message">Message: <br/></label>
            <textarea className="msgBox" id="message" required />
        </div>
        <div className="subButton">
            <button className="primaryButton" type="submit">{status}</button>
        </div>
        </form>
    </div>
  );
};

export default ContactForm;
