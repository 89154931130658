import './intro-message.css';
import { NavLink } from 'react-router-dom'

const IntroMessage = () => {
    return (
        <div className='introContainer'>
            <h1 className='mainTitle'>Welcome</h1>
            <div className='introDescription'>
                <p>St. George is a parish of the Antiochian Orthodox Christian Archdiocese of North America. The mission of St. George Church is to serve God in the Greater Detroit Area by commitment to the Gospel Command and to grow in the knowledge of Jesus Christ through faith, hope, and love.</p>
                <p>St. George Church follows the faith and practice of the Apostles and Disciples of Christ handed down by the ancient Christian fathers and twenty centuries of Church tradition. Genuine Christian life nurtures and stimulates our spiritual and moral development.</p>
                <p>To join the community of St. George of Troy or to find out more information, please give your name, address, and telephone number to one of the ushers. Father Joseph will be happy to contact you and answer any questions. We hope this day will be spiritually rewarding for you. Please join us in the hall after liturgy for our Coffee Social and fellowship.</p>
                <p>Please check the <NavLink to="/contact" onClick={() => {}}>Contact page.</NavLink></p>
                <p className='introAuthor'>- V. Rev. Fr. Joseph Antypas</p>
            </div>
        </div>
    )
}

export default IntroMessage;
