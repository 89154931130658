import React, { useEffect, useState } from "react";
import './carousel.css';
import ChurchPic1 from '../../../assets/church-1.jpg'
import ChurchPic2 from '../../../assets/church-2.jpg'
import ChurchPic3 from '../../../assets/church-3.jpg'

const Carousel = () => {
    const data = [ChurchPic1, ChurchPic2, ChurchPic3];
    const [currentIndex, setCurrentIndex] = useState(0)
    const carouselInfiniteScroll = () => {
        if (currentIndex === data.length-1) {
            return setCurrentIndex(0)
        }
        return setCurrentIndex(currentIndex + 1)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            carouselInfiniteScroll()
        }, 5000)
        return () => clearInterval(interval)
    })

    return (
        <div className="carousel-container">
            {
                data.map((item, index) => {
                    return <img className="carousel-item"
                    style={{transform: `translate(-${currentIndex * 100}%)`}}
                    key={index} src={item} alt="Church Carousel" />
                })
            }
        </div>
    )
}

export default Carousel;
