import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './pdf-viewer.css';

const PDFViewer = (props) => {
	pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const pdfPath = props.pdfName;

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () => setPageNumber(
		pageNumber - 1 <= 1 ? 1 : pageNumber - 1
	);

	const goToNextPage = () => setPageNumber(
		pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
	);

	return (
		<div className="page">
    		<nav className='pdf'>
        		<button onClick={goToPrevPage} className={'pdf previous'}>
          			Prev
        		</button>
        		<button onClick={goToNextPage} className={'pdf next'}>
          			Next
        		</button>
        		<p>
          			Page {pageNumber} of {numPages}
        		</p>
      		</nav>
      		<Document file={pdfPath} onLoadSuccess={onDocumentLoadSuccess}>
	  			<Page pageNumber={pageNumber} renderTextLayer={false}/>
      		</Document>
    	</div>
	);
};

export default PDFViewer;
