import React from 'react';
import EventList from '../components/event/event-list/event-list';

const Events = () => {
	return (
		<div className='events-main'>
			<h1 className='mainTitle'>Church Community Events: </h1>
			<EventList title="Upcoming" section="1"/>
			<EventList title="Past" section ="2"/>
		</div>
	);
};

export default Events;
