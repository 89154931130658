import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import './home-info.css';
import '../intro-message/intro-message.css';

const HomeInfo = () => {
  const [backendData, setBackendData] = useState([]);

    // useEffect is used to fetch the 'api' route, then transform
    // the response into a json response
    // then transform .json into data
    // then set it into the backendData var using the setBackendData.
    // The empty array at the end, to only run this function once.
	useEffect(() => {
		fetch("/home").then(
			response => response.json()
		).then(data => {
			setBackendData(data);
		});
	}, []);

    return (
    <div>
        {(typeof backendData === "undefined") ? (
            <p>Loading...</p>
        ) : (
            <MainWrapper displayData={backendData} />
        )}
    </div>
    )
}

function MainWrapper(props) {
    return(
      <div>
      { props.displayData.map((dataObj, index) => {
          return (
            <div>
              <h1 className='mainTitle'>
                { dataObj.title }
              </h1>
              
              { dataObj.objcs.map(item => {
                return <ScheduleCardWrapper 
                key = {item._id}
                scheduleObject={item} />;
              })}
            </div>        
          );
      })}
      </div>
    );
}

function ScheduleCardWrapper(props) {
    return(
      <ScheduleCard
        key={props.scheduleObject._id}
        title={props.scheduleObject.title}
        time={props.scheduleObject.timestamp}
        // description={scheduleObject.subtitle}
      />
    );
}

function ScheduleCard(props) {
  let displayInfo;
  if (props.title === "Divine Liturgy") {
    const newPath = 'books/theDivineLiturgy.pdf';
    displayInfo = <NavLink to="/observers" state={{obID: newPath, isIdSent: true}}> Click here for congregational participation.</NavLink>;
  } else {
    displayInfo = <p></p>;
  }
    return(
      <div className="homeInfo">
      <h2>{props.title}<br/></h2>
      <ul className="homeInfo">
      <div className="introDescription">
        <li>
        From {props.time.from}
        </li>
        <li>
          {/* {props.description} */}
          { displayInfo }
        </li>
        </div>
      </ul>
      </div>
    );
}

export default HomeInfo;
