import React from "react";
import '../event-list-item/event-list-item.css'
import { NavLink } from 'react-router-dom';

function EventListItem(props) {
    const imageAssetName = "events/" + props.img + ".jpg";
	const displayDate = new Date(props.obj.date);
	const options = {
        day: "numeric",
        month: "long",
		year: "numeric"
    }
    // console.log("date is: " + props.date);
	const currentDayInLocal = displayDate.toLocaleDateString("en-US", options);

    return(
        <div>
            <div className="container-event-list-item">
                <h2 className='event-list-item'>{props.obj.name}</h2>
                <NavLink to="/events/id" state={{obj: props}}>
                    <img className="event-item" key={props.id} src={imageAssetName} alt="Church Carousel" />
                </NavLink>
                <h3 className='event-list-item'>{currentDayInLocal}</h3>
            </div>
        </div>
    );
}

export default EventListItem;
