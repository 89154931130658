import React, {useEffect, useState} from 'react';
import './event-list.css'
import EventListItem from '../event-list-item/event-list-item';

function EventList(props) {
  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: 'smooth'
    });
  };

  const [backendData, setBackendData] = useState([]);
  let [postBody] = useState(props.section);

  useEffect(() => {
    let section;
    if (postBody === "1") {
      section = {isUpcomming: true}
    } else {
      section = {isUpcomming: false}
    }
    fetch("/event", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(section),
    }).then(
      response => response.json()
    ).then(data => {
      setBackendData(data);
    });
  }, [postBody]);

  return (
    <div>
      <header>
        <h1>{props.title}</h1>
      </header>
      <div className="container-event-list" onWheel={handleScroll}>
      {(typeof backendData === "undefined") ? (
            <p>Loading...</p>
      ) : (
			    backendData.map((item, index) => {
                return <EventListItem 
                            key={index} 
                            img={item.photos.coverImageName}
                            obj={item}
                            />
			    })
        )}
      </div>
      <footer>
        {/* Footer content */}
      </footer>
    </div>
  );
}

export default EventList;
