import React from 'react';
import '../App.css';
import ContactInfo from '../components/contact/contact-info/contact-info';
import ContactForm from '../components/contact/send-message/send-message';

const Contact = () => {
	return (
		<div className='contactContainer'>
			<ContactInfo />
			<ContactForm />
		</div>
	);
};

export default Contact;
