import React from "react";
import { NavLink } from 'react-router-dom'

import './footer.css';

// https://www.geeksforgeeks.org/how-to-create-a-simple-responsive-footer-in-react-js/
const Footer = () => {
return (
	<div className="Box">
	<h1 className="footer">
          St. George of Troy<br/>
          Antiochian Orthodox Church
	</h1>
	<div className="Container">
    <p className="footer">2160 E Maple Rd, Troy, MI 48083<br/>
    office@stgeorgeoftroy.com (248) 589-0480<br/>
    Office hours: 8:00 AM - 5:00 PM</p>
    <div className="foot-elements">
    <ul>
        <li>
            <NavLink to="/">Home</NavLink>
        </li>
        <li>
              <NavLink to="/calendar">Calendar</NavLink>
        </li>
        <li>
              <NavLink to="/events">Events</NavLink>
        </li>
        <li>
              <NavLink to="/observers" state={{obID: "NotNull", isIdSent: false}}>Observers</NavLink>
        </li>
        <li>
              <NavLink to="/contact">Contact</NavLink>
        </li>
    </ul>
    </div>
    <p className="footer">© 2023 St. George of Troy Antiochian Orthodox Church</p>
	</div>
	</div>
)};

export default Footer;
