import './observer-card.css';
import { NavLink } from 'react-router-dom';

const ObserverCard = (props) => {
	return(
		<ObserverCardWrapper
			key={props._id}
			id={props._id}
			path={props.fileName}
			title={props.title}
			date={props.date}
		/>
	);
}

function ObserverCardWrapper(props) {
    const newPath = 'observers/pdf/' + props.path + '.pdf';
	const displayDate = new Date(props.date);
	const options = {
        day: "numeric",
        month: "long",
		year: "numeric"
    }
	const currentDayInLocal = displayDate.toLocaleDateString("en-US", options);

	return(
	<div>
		<NavLink to="/observers" state={{obID: newPath, isIdSent: true}}>
			<div className="observerButton">
				<div className="observerImage">
					{/* <img src={`observers/thumbnail/${props.path}.jpg`} alt="Logo"/> */}
					<img src={`observers/thumbnail/03-26-23-observer.jpg`} alt="Logo"/>
				</div>
				<div className="observerButtonLabel">
					{currentDayInLocal}
				</div>
			</div>
		</NavLink>
	</div>
	);  
}

export default ObserverCard;
