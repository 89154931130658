import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import Brand from '../../assets/logo.png'
import { ReactComponent as Hamburger } from '../../assets/hamburger-menu.svg'
import './navbar.css'

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)

  function handleShowNavbar(isMenuClicked) {
    if (isMenuClicked || typeof isMenuClicked === 'undefined') {
      setShowNavbar(!showNavbar)
    } else {
      setShowNavbar(false)
    }
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          {/* https://stackoverflow.com/questions/44154939/load-local-images-in-react-js */}
          <img src={Brand} className="logo" alt="logo" />
        </div>
        <div className="title">
          St. George of Troy<br/>
          Antiochian Orthodox Church
        </div>
        <div className="menu-icon" onClick={() => { handleShowNavbar(true);}}>
          <Hamburger width="60px" height="60px"/>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
            <NavLink to="/" onClick={() => { handleShowNavbar(false);}}>Home</NavLink>
            </li>
            <li>
              <NavLink to="/calendar" onClick={() => { handleShowNavbar(false);}}>Calendar</NavLink>
            </li>
            <li>
              <NavLink to="/events" onClick={() => { handleShowNavbar(false);}}>Events</NavLink>
            </li>
            <li>
              <NavLink to="/observers" state={{obID: "NotNull", isIdSent: false}} onClick={() => { handleShowNavbar(false);}}>Observers</NavLink>
            </li>
            <li>
              <NavLink to="/contact" onClick={() => { handleShowNavbar(false);}}>Contact</NavLink>
            </li>
            <li>
              <a href="https://www.paypal.com/donate/?hosted_button_id=TMSD9ZKKZE3VA" target="_blank" rel="external nofollow noopener noreferrer">
                <button className='primaryButton'> Donate </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;