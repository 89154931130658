import React, { useState } from "react";
import "./calendar-component.css";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/core";
import "@fullcalendar/daygrid";
import "@fullcalendar/timegrid";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

let eventClickedTitle;
let eventStartDate;
let eventEndDate;
let eventLocation;

// https://stackoverflow.com/questions/9532664/how-to-display-a-date-object-in-a-specific-format-using-javascript
function setDetailValues(props) {
  eventClickedTitle = props.event.title;
  eventStartDate = props.event.start.toLocaleString("en-GB", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  });
  eventEndDate = props.event.end.toLocaleString("en-GB", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  });
  eventLocation = props.event.extendedProps.location;
}

const CalendarComponent = () => {  
  const [openDialog, handleDisplay] = React.useState(false);

  const handleClose = () => {
     handleDisplay(false);
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };

  const handleEventClick = (arg) => { // bind with an arrow function
    setDetailValues(arg);
    openDialogBox();
  };
  
  const [calendarData, setCalendarData] = useState([]);

  const handleEvents = (events) => {
    if (calendarData.length === 0) {
      fetch("/calendar").then(
        response => response.json()
      ).then(data => {
        setCalendarData(data);
      });
    }
  }

  return (
    <div className="Calendar">
    <FullCalendar
    defaultView="dayGridMonth"
    header={{
      left: "prev,next",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay"
    }}
    themeSystem="Simplex"
    plugins={[dayGridPlugin]}
    events={calendarData}
    editable={true}
    initialEvents={calendarData} // alternatively, use the `events` setting to fetch from a feed
    eventClick={handleEventClick}
    eventColor="#000000"
    eventsSet={handleEvents}
    />
    <Dialog onClose = {handleClose} open = {openDialog}>
      <DialogTitle>
        <h3 style = {{ padding: "0px 10px" }}>
        {eventClickedTitle} Service 
        </h3>
      </DialogTitle>
      <div className = "divStyle">
        <ul style={{
          listStyleType: "none",
          marginLeft: "-35px",
          marginTop: "-40px"
         }}>
          <li>{eventStartDate} To {eventEndDate}</li>
          <li>📍{eventLocation} </li>
        </ul>
        <button className = "dialogueButtonStyle" onClick = {handleClose}>
          Close
        </button>
      </div>
    </Dialog>
  </div>
  );
}

export default CalendarComponent;