import React, { useEffect, useState } from "react";
import PDFViewer from '../components/observer/pdf-viewer/pdf-viewer.js';
import { useLocation } from 'react-router-dom';
import '../App.css';
import ObserverCard from "../components/observer/observer-card/observer-card.js";

const Observers = (props) => {
	const [backendData, setBackendData] = useState([]);
	const location = useLocation();
	const { state } = location;

	useEffect(() => {
		console.log("this is the fetching");
		fetch("/observer").then(
			response => response.json()
		).then(data => {
			console.log("this is the setting");
			let sortedData = data.sort((a, b) => new Date(...a.date.split(':').reverse()) - new Date(...b.date.split(':').reverse()));
			setBackendData(sortedData);
		});
	}, []);

	if (state.isIdSent === true) {
		return (
			<PDFViewer pdfName={ state.obID }/>
		);
	} else {
		return (
			<div>
				<h1 className='mainTitle'>Weekly Observers </h1>
				<div style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'spaceBetween',
					margin: '30px',
					maxWidth: '100%'
				}}>
					{(typeof backendData === "undefined") ? (
						<p>Loading...</p>
					) : (
						backendData.map(ObserverCard)
					)}
				</div>
			</div>
		);
	}
};

export default Observers;
