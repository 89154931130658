import Carousel from '../components/home/carousel/carousel.js';
import IntroMessage from "../components/home/intro-message/intro-message.js";
import HomeInfo from "../components/home/home-info/home-info.js";
import '../App.css';

const Home = () => {
  console.log("fetch");
  return (
    <div>
	  <Carousel />
    <IntroMessage />
    <HomeInfo />
    </div>
  );
};

export default Home;
